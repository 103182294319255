import { useState } from 'react';

import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import { AddressApi } from '@/api';
import { IAddress, IAddressForm } from '@/models';
import { getAddressLocation } from '@/services';
import { googlePlaceService } from '@/services/service.google';
import { useToastStore } from '@/store';

import { AddressSearch, AddressOptionType } from './AddressSearch';
import { BaseFieldProps } from '../../FormElements';

interface AddressItemProps extends BaseFieldProps {
  name?: string;
  value?: AddressOptionType;
  locationRestriction?: google.maps.LatLngBoundsLiteral;
  updateCurrentAddress: (newAddress: IAddressForm | null) => void;
}

export const AddressItem = (props: AddressItemProps) => {
  const { label, value, error, locationRestriction, updateCurrentAddress } =
    props;
  const { updateToast } = useToastStore();
  const [options, setOptions] = useState<AddressOptionType[]>([]);

  const retrieveFullAddress = async (placeId: string) => {
    try {
      const addressDetails = await googlePlaceService.getPlaceDetails(placeId);
      if (addressDetails) {
        addNewAddress(addressDetails);
        updateCurrentAddress(addressDetails);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const addNewAddress = async (newAddress: IAddressForm) => {
    try {
      const res = await AddressApi.createAddress(newAddress);
      updateCurrentAddress(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onSearch = async (inputValue: string) => {
    try {
      const res = await googlePlaceService.getPlacePredictions(
        inputValue,
        locationRestriction,
      );
      const newOptions = res.map(({ structured_formatting, place_id }) => ({
        mainText: structured_formatting.main_text,
        secondaryText: structured_formatting.secondary_text,
        placeId: place_id,
      }));
      setOptions(newOptions);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleChange = (newValue: string | AddressOptionType | null) => {
    if (!!newValue && typeof newValue !== 'string') {
      retrieveFullAddress(String(newValue.placeId));
    } else updateCurrentAddress(null);
  };

  const handleInputChange = (newValue: string) => {
    if (newValue.length > 0) onSearch(newValue);
    else setOptions([]);
  };

  const getInputText = (option: AddressOptionType | string) => {
    if (typeof option === 'string') return option;
    const { mainText, secondaryText } = option || {};

    if (mainText && secondaryText) return `${mainText} ${secondaryText}`;
    return getAddressLocation(option as IAddress);
  };

  const checkOptionEqualToValue = (
    option: string | AddressOptionType,
    newValue: string | AddressOptionType,
  ) => {
    if (typeof option === 'string' || typeof newValue === 'string') {
      return option === newValue;
    }
    return `${option.mainText}` === `${newValue?.addressLabel}`;
  };

  const renderOption = (option: AddressOptionType) => {
    const { mainText, secondaryText } = option;
    return (
      <Stack sx={{ ml: 1 }}>
        <Typography variant="subtitle2">{mainText}</Typography>
        <Typography variant="body2">{secondaryText}</Typography>
      </Stack>
    );
  };

  return (
    <Stack>
      {!!label && <InputLabel>{label}</InputLabel>}
      <AddressSearch
        filterOptions={(filterOptions) => filterOptions}
        options={options}
        value={value}
        onChange={(_, selected) => {
          handleChange(selected);
        }}
        handleInputChange={handleInputChange}
        getOptionLabel={getInputText}
        renderOption={renderOption}
        isOptionEqualToValue={checkOptionEqualToValue}
      />
      <FormHelperText error>{error}</FormHelperText>
    </Stack>
  );
};
