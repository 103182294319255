import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { SelectChangeEvent, SelectProps } from '@mui/material';

import { Select, SelectItem } from '../Select';
import { FormElementProps } from '../types';

export interface FormSelectFieldProps
  extends Omit<SelectProps, 'name' | 'onChange' | 'variant'>,
    FormElementProps {
  label?: string;
  items?: SelectItem[];
  handleChange?: (name: string, value: string | number) => void;
}

/**
 * Renders a select component
 *
 * @param {SelectItem} items - `Array<{ label: string; value: string | number; disabled?: boolean; group?: string}>`
 *
 *  **add item  {group: string, label: '', value: '' } if you want to display groups in the select**
 * @example
 * [
 *  { group: 'Group Title', label: '', value: '' }
 *  { label: 'item label1', value: 'itemValue1' }
 *  { label: 'item label2', value: 'itemValue2' }
 * ]
 */
export const FormSelectField: FC<FormSelectFieldProps> = (props) => {
  const {
    name,
    rules,
    items = [],
    disabled,
    defaultValue = '',
    handleChange,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }: any) => {
        return (
          <Select
            {...rest}
            {...field}
            name={name}
            onChange={(event: SelectChangeEvent) => {
              if (handleChange != null)
                handleChange(event.target.name, event.target.value);
              field.onChange(event);
            }}
            disabled={disabled}
            items={items}
            error={error?.message}
          />
        );
      }}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
};
