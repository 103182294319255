import colors from '@/theme/variables/colors';

import { IAddress, IAddressForm } from './address';
import { IAuditModel, ITimestampsModel, ObjectId } from './base';
import { IPolygonLayer } from './geo';
import { IIncidentCode } from './incident.code';
import { IMasterNamePerson, IMasterNameRelationBehavior } from './master.names';
import { AssignedUnitShiftStatus, IUnitType } from './unit';
import { IUnitShift } from './unit.shift';
import { IUser } from './user';

import { KaseMasterNameInvolvement, IMasterVehicle } from '.';

export enum AssignmentStatus {
  NEW = 'NEW',
  PARTIAL_ASSIGNED = 'PARTIAL_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
}

export enum CFSMasterNameInvolvement {
  PRIMARY_REPORTER = 'PRIMARY_REPORTER', // There can be only one PRIMARY_REPORTER in one cfs.
  REPORTER = 'REPORTER',
  SUBJECT = 'SUBJECT',
}

export interface IAddressAdditionalInfo {
  beat?: IPolygonLayer;
  municipality?: IPolygonLayer;
  address: string;
}

export interface IAddressInfo {
  address?: IAddress;
  instructions?: string;
  additionalInfo?: IAddressAdditionalInfo;
}

export enum TSeverity {
  'HIGH' = 'HIGH',
  'MEDIUM' = 'MEDIUM',
  'LOW' = 'LOW',
}

export interface IAdditionalInfo {
  createdBy: {
    _id?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
  };
  description: string;
  isNew?: boolean;
  createdAt: string;
}

export interface IReporter {
  reportTime?: string;
  callerPhoneNumber?: string;
  contactPhoneNumber?: string;
  isCallingForSelf?: boolean;
  isKnowSubject?: boolean;
  notOnLocation?: boolean;
  notWantToBeSeen?: boolean;
}

export interface IRequiredUnit {
  unitType: IUnitType;
  number: number;
}

export interface IRequiredUnitForm {
  unitType: string;
  number: number;
}

export interface IAssignedUnitShift extends ObjectId, ITimestampsModel {
  isDispatchedToHQ?: boolean;
  unitShift: IUnitShift;
  status: AssignedUnitShiftStatus;
  unAssignedAt?: Date;
}

export type ICFSInvolvement = {
  behavior?: IMasterNameRelationBehavior;
  involvement?: CFSMasterNameInvolvement[] | KaseMasterNameInvolvement;
};

export interface IAddressInfoForm {
  address?: IAddressForm;
  instructions?: string;
}

export interface ICFSMasterNameForm {
  cfsId: string;
  nameId: string;
  relationship: {
    behavior?: string;
    involvement: CFSMasterNameInvolvement[];
  };
}

export interface ICFSForm extends ObjectId {
  number?: string;
  addressInfo?: IAddressInfoForm;
  severity: TSeverity;
  shortDescription: string;
  incidentCodes: string[];
  requiredUnits?: IRequiredUnit[];
  reporter: IReporter;
}

export interface ICFS
  extends Omit<
      ICFSForm,
      'incidentCodes' | 'addressInfo' | 'newAdditionalInfo' | 'requiredUnits'
    >,
    IAuditModel {
  number: string;
  addressInfo?: IAddressInfo;
  firstUnitArrivedAt?: string;
  isClosed: boolean;
  closeReason?: string;
  incidentCodes: IIncidentCode[];
  additionalInfo: IAdditionalInfo[];
  assignedUnitShifts: IAssignedUnitShift[];
  assignmentStatus: AssignmentStatus;
  isReadyToDispatch: boolean;
  requiredUnits: IRequiredUnit[];
  primaryReporter?: IMasterNamePerson;
  createdBy: IUser;
  names: number;
  vehicles: number;
  hasUnsetInvolvementName: boolean;
  unitShiftDistances: IUnitShiftDistance[];
  wasReadyToDispatchAt?: Date | null;
}

export interface IRecord extends IAuditModel {
  names: string;
  location: string;
  createdBy: string;
  status: string;
  dateApproved: Date;
  kaseId: number;
}

export interface ICFSCloseReason extends ObjectId {
  reason: string;
}

export interface ICFSMasterVehicle extends IAuditModel {
  cfs: string;
  masterVehicle: IMasterVehicle;
  relationship?: string;
}

export interface ICFSMasterName extends IAuditModel {
  cfs: string;
  masterName: IMasterNamePerson;
  relationship?: ICFSInvolvement;
}

export enum CFSEvent {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  UPDATED_ADDRESS_INFO = 'UPDATED_ADDRESS_INFO',
  UPDATED_INCIDENT_CODES = 'UPDATED_INCIDENT_CODES',
  UPDATED_REQUIRED_UNITS = 'UPDATED_REQUIRED_UNITS',
  UPDATED_ADDITIONAL_INFO = 'UPDATED_ADDITIONAL_INFO',
  UPDATED_AI_SHORT_DESCRIPTION = 'UPDATED_AI_SHORT_DESCRIPTION',
  CLOSED = 'CLOSED',
  REOPENED = 'REOPENED',
  READY_TO_DISPATCH = 'READY_TO_DISPATCH',

  // AssignedUnitShit events
  UNIT_SHIFT_ASSIGNED = 'UNIT_SHIFT_ASSIGNED',
  UNIT_SHIFT_IS_EN_ROUTE = 'UNIT_SHIFT_IS_EN_ROUTE',
  UNIT_SHIFT_IS_ON_SCENE = 'UNIT_SHIFT_IS_ON_SCENE',
  UNIT_SHIFT_IS_IN_PROGRESS = 'UNIT_SHIFT_IS_IN_PROGRESS',
  UNIT_SHIFT_COMPLETED = 'UNIT_SHIFT_COMPLETED',
  UNIT_SHIFT_CANCELED = 'UNIT_SHIFT_CANCELED',
  DISPATCH_TO_HQ_ENABLED = 'DISPATCH_TO_HQ_ENABLED',
  DISPATCH_TO_HQ_DISABLED = 'DISPATCH_TO_HQ_DISABLED',

  // CFS MasterName events
  LINKED_MASTER_NAME = 'LINKED_MASTER_NAME',
  UPDATED_MASTER_NAME_RELATIONSHIP = 'UPDATED_MASTER_NAME_RELATIONSHIP', // Updated relationship of cfs and master name
  UNLINKED_MASTER_NAME = 'UNLINKED_MASTER_NAME',

  // CFS MasterVehicle events
  LINKED_MASTER_VEHICLE = 'LINKED_MASTER_VEHICLE',
  UNLINKED_MASTER_VEHICLE = 'UNLINKED_MASTER_VEHICLE',
}

export const CFSEvent_STATUS = {
  [CFSEvent.CREATED]: {
    label: 'Created',
    color: 'white',
    bgColor: colors.secondary.main,
  },
  [CFSEvent.UPDATED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UPDATED_ADDRESS_INFO]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UPDATED_INCIDENT_CODES]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UPDATED_REQUIRED_UNITS]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UPDATED_ADDITIONAL_INFO]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.secondary.main,
  },

  [CFSEvent.UPDATED_AI_SHORT_DESCRIPTION]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UPDATED_MASTER_NAME_RELATIONSHIP]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.CLOSED]: {
    label: 'Closed',
    color: 'black',
    bgColor: colors.grey[10],
  },
  [CFSEvent.REOPENED]: {
    label: 'Reopened',
    color: 'white',
    bgColor: colors.warning.main,
  },
  [CFSEvent.LINKED_MASTER_NAME]: {
    label: 'Linked',
    color: 'black',
    bgColor: colors.grey[10],
  },
  [CFSEvent.UNLINKED_MASTER_NAME]: {
    label: 'Unlinked',
    color: 'black',
    bgColor: colors.grey[10],
  },

  [CFSEvent.UNIT_SHIFT_ASSIGNED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNIT_SHIFT_IS_EN_ROUTE]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNIT_SHIFT_IS_ON_SCENE]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNIT_SHIFT_IS_IN_PROGRESS]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNIT_SHIFT_COMPLETED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNIT_SHIFT_CANCELED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.DISPATCH_TO_HQ_ENABLED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.DISPATCH_TO_HQ_DISABLED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },

  [CFSEvent.READY_TO_DISPATCH]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.LINKED_MASTER_VEHICLE]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [CFSEvent.UNLINKED_MASTER_VEHICLE]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
};

export interface ICFSLog extends IAuditModel {
  app: string;
  cfs: string;
  database: string;
  event: CFSEvent;
  field: string;
  data?: {
    new?: Record<string, any>;
    deleted?: any;
  };
  logCollection: string;
  collection: string;
  createdBy: IUser;
  account: string;
}

export interface IRequiredUnitsLog {
  unitType?: string;
  number?: string;
}

export interface IAssignedUnitShiftsLog {
  unitShift?: string;
  status?: string;
}

export interface IUnitShiftDistance
  extends Omit<ITimestampsModel, 'updatedAt'> {
  unitShift: string;
  distance: number;
}
