import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Stack } from '@mui/material';

import { Page, TableSetting } from '@/components';
import { LocalStoragePrefix } from '@/constants';
import { withLoader, WithLoaderProps } from '@/hocs';
import { useUserStore } from '@/store';
import { getLocalStorageItem } from '@/utils';

import { MasterIndexNameListTable } from './components/NameListTable';
import { masterIndexNameListTableColumns } from './data';

const MasterIndexNameList: FC<WithLoaderProps> = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  useEffect(() => {
    if (user?._id) {
      setHiddenColumns(
        getLocalStorageItem({
          key: String(user._id),
          prefix: LocalStoragePrefix.MasterIndexTableSetting,
          shouldParse: true,
        }) || [],
      );
    }
  }, [user?._id]);

  return (
    <Page title="Master Index Name List">
      <Stack className="sticky-header-content">
        <div></div>
        <Stack columnGap={2} flexDirection="row">
          <TableSetting
            tableColumns={masterIndexNameListTableColumns}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            storageKeyPrefix={LocalStoragePrefix.MasterIndexTableSetting}
          />
          <Button
            color="error"
            variant="contained"
            size="medium"
            onClick={() => navigate('/master-index/names/new')}
          >
            Add name
          </Button>
        </Stack>
      </Stack>
      <MasterIndexNameListTable hiddenColumns={hiddenColumns} />
    </Page>
  );
};

export default withLoader(MasterIndexNameList);
