import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { FormHelperText, InputLabel } from '@mui/material';
import { EditorState, Modifier } from 'draft-js';

import { DefaultEditorConfig } from './data';
import { FormTextEditorFieldRoot } from './styles';
import { FormElementProps } from '../types';

export type ISuggestionItem = {
  text: string;
  value: string | number;
};

export interface FormTextEditorFieldProps
  extends Omit<EditorProps, 'onChange'>,
    FormElementProps {
  label?: string;
  handleChange?: (name: string, value: EditorState) => void;
}

export const FormTextEditorField = (props: FormTextEditorFieldProps) => {
  const { name, label, rules, handleChange, mention, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  const handlePastedText = (
    text: string,
    _html: string | undefined,
    editorState: EditorState,
    onChange: (newState: EditorState) => void,
  ) => {
    const newContentState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text, // Insert plain text, ignoring any HTML styles
    );
    onChange(
      EditorState.push(editorState, newContentState, 'insert-characters'),
    );
    return true; // Returning true prevents the default paste behavior
  };

  return (
    <FormTextEditorFieldRoot flex={1}>
      {label && <InputLabel>{label}</InputLabel>}
      <Controller
        control={control}
        name={name}
        rules={{
          validate: {
            required: (v: EditorState) => {
              if (!rules?.required) return '';
              return (
                (!!v && v.getCurrentContent().getPlainText().length > 0) ||
                'This field is required.'
              );
            },
            ...rules?.validate,
          },
        }}
        render={({ field }: any) => (
          <Editor
            toolbar={DefaultEditorConfig.toolbar}
            mention={{ ...DefaultEditorConfig.mention, ...mention }}
            spellCheck={true}
            editorState={field.value}
            editorStyle={{ minHeight: 250 }}
            onEditorStateChange={(newEditorState: EditorState) => {
              field.onChange(newEditorState);
              if (handleChange) {
                handleChange(name, newEditorState);
              }
            }}
            handlePastedText={(text, html) =>
              handlePastedText(text, html, field.value, field.onChange)
            }
            {...rest}
          />
        )}
      />
      {error && error?.message && (
        <FormHelperText error>{error?.message}</FormHelperText>
      )}
    </FormTextEditorFieldRoot>
  );
};
