import { ReactNode } from 'react';

import { IconButton } from '@mui/material';
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

import { IcClose } from '@/assets/images';
import defaultMarkerImage from '@/assets/images/pngs/marker.png';

export interface IMarker extends Omit<AdvancedMarkerProps, 'position'> {
  key: string;
  position: google.maps.LatLngLiteral;
  icon?: ReactNode | string;
  info?: string | ReactNode;
}

interface MapMarkerProps extends IMarker {
  showInfo: boolean;
  toggleInfo: () => void;
}

export const MapMarker = (props: MapMarkerProps) => {
  const { icon, info, showInfo, toggleInfo, onClick, ...rest } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleClickMarker = (e: google.maps.MapMouseEvent) => {
    if (info) {
      toggleInfo();
    } else if (onClick) onClick(e);
  };

  return (
    <>
      <AdvancedMarker ref={markerRef} onClick={handleClickMarker} {...rest}>
        {icon ? (
          typeof icon === 'string' ? (
            <img src={icon} style={{ maxHeight: 25 }} />
          ) : (
            icon
          )
        ) : (
          <img src={defaultMarkerImage} style={{ maxHeight: 25 }} />
        )}
      </AdvancedMarker>
      {!!info && showInfo && (
        <InfoWindow anchor={marker} maxWidth={250}>
          <IconButton
            onClick={toggleInfo}
            sx={{ position: 'absolute', right: '2px', top: '2px', padding: 0 }}
          >
            <IcClose style={{ width: 15, height: 15 }} />
          </IconButton>
          {info}
        </InfoWindow>
      )}
    </>
  );
};
