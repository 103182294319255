import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import moment, { Moment } from 'moment';

import { DATE_FORMAT } from '@/config';

import { InputProps } from '..';
import { DatePicker } from '../DatePicker';
import { FormElementProps } from '../types';

export interface DatePickersFormFieldProps
  extends Omit<InputProps, 'name'>,
    FormElementProps {
  format?: string; // format how to emit date
  displayFormat?: string; // format how to display date
  minDate?: Moment;
  maxDate?: Moment;
  handleChange?: (name: string, value: string | number) => void;
  handleAccept?: (name: string, value: string | number) => void;
}

export const FormDateField: FC<DatePickersFormFieldProps> = (props) => {
  const {
    name,
    rules,
    defaultValue,
    handleChange,
    format = 'YYYY-MM-DD',
    displayFormat = DATE_FORMAT,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={Boolean(defaultValue) || ''}
      name={name}
      render={({ field: { onBlur, onChange, ...restField } }: any) => (
        <DatePicker
          {...rest}
          {...restField}
          error={error?.message}
          format={displayFormat}
          value={moment(restField.value)}
          onChange={(value: Moment) => {
            if (handleChange != null) handleChange(name, value.format(format));
            onChange(value.format(format));
          }}
        />
      )}
      rules={rules}
    />
  );
};
