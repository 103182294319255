import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Stack, StackProps, Typography } from '@mui/material';

import { IcArrowLeft } from '@/assets/images';

import { PageHeaderRoot } from './styles';

interface PageHeaderProps extends PropsWithChildren, Omit<StackProps, 'title'> {
  backRoute?: string;
  title?: ReactNode | string;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { backRoute, title, children, ...rest } = props;

  const navigate = useNavigate();

  const goBack = () => {
    if (backRoute) navigate(backRoute);
  };

  return (
    <PageHeaderRoot {...rest}>
      <Stack className="header-breadcrumb">
        {!!backRoute && (
          <IconButton className="btnBack" onClick={goBack}>
            <IcArrowLeft />
          </IconButton>
        )}
        {!!title && <Typography className="page-title">{title}</Typography>}
      </Stack>
      <Stack
        flex={1}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="wrap"
        columnGap={2}
      >
        {children}
      </Stack>
    </PageHeaderRoot>
  );
};
