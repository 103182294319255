import { Gender, Race } from '@/constants/form_inputs';
import { IMasterNamePerson, MasterNameType } from '@/models';

export const masterIndexNameDummyTableData: IMasterNamePerson[] = [
  {
    _id: '1',
    type: MasterNameType.PERSON,
    name: 'full name',
    firstName: 'Full',
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '2',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.FEMALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,

    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '3',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '4',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '5',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '6',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '7',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '8',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: '9',
    name: 'full name',
    firstName: 'Full',
    type: MasterNameType.PERSON,
    lastName: 'name',
    aliasName: 'Alias name',
    sex: Gender.MALE,
    birthday: '1990-02-23',
    ssn: '12345',
    race: Race.WHITE,
    isVerified: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
