import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { IFile } from '@/models';
import { getSasTokenFromFileUrl } from '@/services';
import { useFileStore, useToastStore } from '@/store';

import { FileListItem } from './FileListItem';
import { FileListRoot } from './styles';

export type FilePickerDirection = 'vertical' | 'horizontal';

export interface FileListProps {
  files?: IFile[];
  onRemove: (index: number) => void;
  itemClassName?: string;
  enableEditDescription?: boolean;
  direction?: FilePickerDirection;
}

export const FileList = (props: FileListProps) => {
  const {
    files = [],
    onRemove,
    itemClassName,
    enableEditDescription,
    direction = 'horizontal',
  } = props;

  const { fetching } = useFileStore();
  const { updateToast } = useToastStore();
  const [sasToken, setSasToken] = useState<string>();

  useEffect(() => {
    if (!fetching && files?.length) {
      fetchSasToken();
    }
  }, [files, fetching]);

  const fetchSasToken = async () => {
    try {
      const fileWithUrl = files.find(({ url }) => !!url);
      if (fileWithUrl) {
        const fileSasToken = await getSasTokenFromFileUrl(fileWithUrl.url);
        setSasToken(fileSasToken);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <FileListRoot
      className={clsx('fileList', direction === 'vertical' && 'vertical')}
    >
      {files.map((file, index) => (
        <FileListItem
          key={file.name}
          item={file}
          enableEditDescription={enableEditDescription}
          className={itemClassName}
          sasToken={sasToken}
          onRemove={() => onRemove(index)}
        />
      ))}
    </FileListRoot>
  );
};
