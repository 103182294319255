import { IAddress } from './address';
import { ObjectId } from './base';
import { IPoint } from './geo';
import { AssignedUnitShiftStatus, IUnit } from './unit';

export enum UnitShiftStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  BUSY = 'BUSY',
  CLOSED = 'CLOSED',
  ON_CALL = 'ON_CALL',
  WAITING = 'WAITING',
}

export interface IAssignedUnitShiftStatusReq {
  status: AssignedUnitShiftStatus;
}

export interface IUnitShiftResource {
  _id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  type: string;
}

export interface IUnitShiftResources {
  users?: IUnitShiftResource[];
  vehicles?: IUnitShiftResource[];
  equipments?: IUnitShiftResource[];
  points?: string[];
  lines?: string[];
  polygons?: string[];
}

export interface IUnitShiftLocation {
  address?: IAddress;
  point: IPoint;
  speed: number;
  heading: number;
  altitude: number;
  ellipsoidal_altitude: number;
}

export interface IUnitShift extends ObjectId, IUnitShiftResources {
  unit: IUnit;
  location?: IUnitShiftLocation | null;
  status: UnitShiftStatus;
  startAt?: string;
  endAt?: string;
}
