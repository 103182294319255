import { useEffect, useState } from 'react';

import {
  Card,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  ImageListProps,
  Skeleton,
  styled,
} from '@mui/material';

import { IFile } from '@/models';
import { getSasTokenFromFileUrl } from '@/services';
import { useFileStore, useToastStore } from '@/store';
import colors from '@/theme/variables/colors';
import { getFileType } from '@/utils';

interface FileGridProps extends Omit<ImageListProps, 'children'> {
  files: IFile[];
}

const FileGridRoot = styled(ImageList)(() => ({
  width: '100%',

  '& .MuiImageListItem-root': {
    width: '100%',
    borderRadius: 8,

    '& img': {
      width: '100%',
      aspectRatio: 1.5,
      objectFit: 'cover',
      borderRadius: 8,
      border: `1px solid ${colors.grey['20']}`,
    },
  },
}));

export const FileGrid = ({ files, ...rest }: FileGridProps) => {
  const { fetching } = useFileStore();
  const { updateToast } = useToastStore();
  const [sasToken, setSasToken] = useState<string>();

  useEffect(() => {
    if (!fetching && files?.length) {
      fetchSasToken();
    }
  }, [files, fetching]);

  const fetchSasToken = async () => {
    try {
      const fileWithUrl = files.find(({ url }) => !!url);
      if (fileWithUrl) {
        const fileSasToken = await getSasTokenFromFileUrl(fileWithUrl.url);
        setSasToken(fileSasToken);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const renderFileItem = (file: IFile) => {
    if (!sasToken || !file.url) return <Skeleton key={file.name} />;

    const fileType = getFileType(file.mimeType);

    if (fileType === 'video') {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={file.url}>
          <Card>
            <CardMedia
              component="video"
              src={`${file.url}?${sasToken}`}
              controls
            />
          </Card>
        </Grid>
      );
    } else if (fileType === 'image') {
      return (
        <ImageListItem key={file.url}>
          <img
            src={`${file.url}?${sasToken}`}
            style={{ objectFit: 'contain' }}
            alt={file.name}
            loading="lazy"
          />
        </ImageListItem>
      );
    } else {
      return <Skeleton key={file.name} />;
    }
  };

  return (
    <FileGridRoot cols={4} gap={16} {...rest}>
      {files?.map((file: IFile) => renderFileItem(file))}
    </FileGridRoot>
  );
};
