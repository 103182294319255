import { ChangeEvent, FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { Input, InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormInputFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string | number) => void;
}

export const FormInputField: FC<FormInputFieldProps> = (props) => {
  const { name, rules, defaultValue, handleChange, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      render={({ field }: any) => (
        <Input
          {...rest}
          {...field}
          error={error?.message}
          name={name}
          onChange={(
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => {
            if (handleChange != null)
              handleChange(event.target.name, event.target.value);
            field.onChange(event);
          }}
        />
      )}
      rules={rules}
    />
  );
};
