import { FC } from 'react';

import { Alert, Snackbar, styled } from '@mui/material';

import { useToastStore } from '@/store';

const Root = styled(Snackbar)(() => ({}));

export const Toast: FC = () => {
  const { open, type, message, vertical, horizontal, onClose, updateToast } =
    useToastStore();

  function handleClose() {
    updateToast({ open: false, type });
    if (onClose) onClose();
  }

  return (
    <Root
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        sx={{ width: '100%' }}
        variant="filled"
      >
        {message}
      </Alert>
    </Root>
  );
};
