import { IAuditModel, ObjectId } from './base';

import { IFile, IMasterNamePerson } from '.';

export enum VehicleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VehicleStyle {
  TDR = '2DR',
  FDR = 'FDR',
}

export interface IVehicleColor extends ObjectId {
  name: string;
  make: string;
  model: string;
  year: number;
}

export interface ILicensePlate {
  number?: string;
  state?: string;
}

export interface IVehicleMake extends ObjectId {
  id: number;
  name: string;
}

export interface IVehicleModel extends ObjectId {
  make: string;
  name: string;
  years: number[];
}

export interface IBaseVehicle {
  name: string;
  make: string;
  model: string;
  year?: number;
  vin: string;
}

export interface IVehicleForm extends IBaseVehicle, ObjectId {
  licensePlate?: ILicensePlate;
  status?: VehicleStatus;
  purchasedAt: string;
  agency?: string;
}

export interface IVehicle
  extends IAuditModel,
    Omit<IVehicleForm, 'make' | 'model'> {
  name: string;
  make?: IVehicleMake;
  model?: IVehicleModel;
}

export interface IMasterVehicleForm extends IAuditModel {
  make: string;
  model?: string;
  year?: number;
  vin?: string;
  licensePlate?: ILicensePlate;
  style?: VehicleStyle;
  color?: string;
  uniqueCharacteristics?: string;
  files?: IFile[];
  owner?: string;
  isVerified?: boolean;
  type?: VehicleType;
}

export interface IMasterVehicle
  extends Omit<IMasterVehicleForm, 'owner' | 'color'> {
  owner?: IMasterNamePerson;
  color?: IVehicleColor;
}

export enum VehicleInvolvement {
  ABANDONED = 'ABANDONED',
  ACCIDENT = 'ACCIDENT',
  ARREST = 'ARREST',
  IMPOUNDED = 'IMPOUNDED',
  TOWED = 'TOWED',
}
export type VehicleInvolvementType = keyof typeof VehicleInvolvement;
export const VehicleInvolvementList = {
  [VehicleInvolvement.ABANDONED]: 'Abandoned',
  [VehicleInvolvement.ACCIDENT]: 'Accident',
  [VehicleInvolvement.ARREST]: 'Arrest',
  [VehicleInvolvement.IMPOUNDED]: 'Impounded',
  [VehicleInvolvement.TOWED]: 'Towed',
} as const;

export enum VehicleType {
  AIRCRAFT = '30',
  AUTOMOBILE = '31',
  BICYCLE = '32',
  BUS = '33',
  TRUCK = '34',
  // TRAILER = '35',
  WATERCRAFT = '36',
  RECREATIONAL_VEHICLE = '37',
  OTHER_MOTOR_VEHICLE = '38',
}

export const VehicleTypeList = {
  [VehicleType.AIRCRAFT]: 'Aircraft',
  [VehicleType.AUTOMOBILE]: 'Automobile',
  [VehicleType.BICYCLE]: 'Bicycle',
  [VehicleType.BUS]: 'Bus',
  // [VehicleType.TRAILER]: 'Trailer',
  [VehicleType.TRUCK]: 'Truck',
  [VehicleType.WATERCRAFT]: 'Watercraft',
  [VehicleType.RECREATIONAL_VEHICLE]: 'Recreational vehicle',
  [VehicleType.OTHER_MOTOR_VEHICLE]: 'Other',
};
