import { ChangeEvent, MouseEvent, useState } from 'react';

import { FormControlLabel, FormGroup, Switch, Menu } from '@mui/material';

import { IcSettings } from '@/assets/images';
import { NarrowButton, TableColumn } from '@/components';
import { LocalStoragePrefix } from '@/constants';
import { useUserStore } from '@/store';
import { setLocalStorageItem } from '@/utils';

type TableSettingProps = {
  tableColumns: TableColumn[];
  hiddenColumns: string[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
  storageKeyPrefix: LocalStoragePrefix;
};

export const TableSetting = ({
  tableColumns,
  hiddenColumns,
  setHiddenColumns,
  storageKeyPrefix,
}: TableSettingProps) => {
  const { user } = useUserStore();
  const handleColumnsDisplayChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedHiddenColumns = checked
      ? hiddenColumns.filter((columns) => columns !== name)
      : [...hiddenColumns, name];
    setHiddenColumns(updatedHiddenColumns);

    setLocalStorageItem({
      prefix: storageKeyPrefix,
      key: user?._id as string,
      value: updatedHiddenColumns,
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openActionMenu = Boolean(anchorEl);
  const handleOpenSettingsMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NarrowButton
        variant="contained"
        color="inherit"
        size="small"
        onClick={handleOpenSettingsMenu}
      >
        <IcSettings></IcSettings>
      </NarrowButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {tableColumns.map((field) =>
          field.key === 'actions' ? null : (
            <FormGroup key={field.key}>
              <FormControlLabel
                style={{ justifyContent: 'space-between' }}
                sx={{ width: 350 }}
                control={
                  <Switch
                    name={field.key}
                    checked={!hiddenColumns?.includes(field.key)}
                    onChange={handleColumnsDisplayChange}
                    color="error"
                  />
                }
                label={field.label}
                labelPlacement="start"
              />
            </FormGroup>
          ),
        )}
      </Menu>
    </>
  );
};
