import { create } from 'zustand';

import { IAccount, IUser } from '@/models';

interface UserState {
  account: IAccount | null;
  user: IUser | null;
  updateUserStore: (state: Partial<Omit<UserState, 'updateUserStore'>>) => void;
}

const initialState = {
  account: null,
  user: null,
};

export const useUserStore = create<UserState>((set) => ({
  ...initialState,
  updateUserStore: (newState) => {
    set((s) => ({ ...s, ...newState }));
  },
}));
