import { forwardRef, ReactNode, Ref, useState } from 'react';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { FormHelperText, InputLabel } from '@mui/material';
import {
  DateTimePickerProps,
  LocalizationProvider,
  DateTimePicker as MuiDatetimePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

import { DateTimePickerRoot, componentCustomStyles } from './styles';
import { BaseFieldProps } from '../types';

export interface BaseDateTimePickerProps
  extends DateTimePickerProps<string>,
    BaseFieldProps {
  hideInput?: boolean;
  pickerIcon?: ReactNode | null;
}

export const DateTimePicker = forwardRef<Ref<any>, BaseDateTimePickerProps>(
  (props, ref) => {
    const {
      label,
      error,
      hideInput,
      pickerIcon = <InsertInvitationIcon />,
      ...rest
    } = props;

    const [open, setOpen] = useState(false);

    return (
      <DateTimePickerRoot className={hideInput ? 'hide-input' : ''}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <InputLabel>{label}</InputLabel>
          <MuiDatetimePicker
            {...rest}
            inputRef={ref as Ref<HTMLInputElement>}
            open={open}
            onClose={() => setOpen(false)}
            sx={{ mt: 0 }}
            closeOnSelect={false}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
                InputProps: {
                  endAdornment: pickerIcon,
                },
              },
              ...componentCustomStyles,
            }}
          />
          <FormHelperText error>{error}</FormHelperText>
        </LocalizationProvider>
      </DateTimePickerRoot>
    );
  },
);

DateTimePicker.displayName = 'Custom Datetime Picker';
