import { FC, useEffect, useState } from 'react';

import { Skeleton } from '@mui/material';

import { AppModal } from '@/components';
import { IFile } from '@/models';

interface ImageViewModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  item: IFile;
  sasToken?: string;
}

export const ImageViewModal: FC<ImageViewModalProps> = ({
  isOpen,
  setOpen,
  item,
  sasToken,
}) => {
  const { name, url, file } = item;

  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (!url && file) {
      const fileReader = new FileReader();
      fileReader.onloadend = function () {
        setImageSrc(fileReader.result as string);
      };

      fileReader.readAsDataURL(file);
    }
  }, [url, file]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <AppModal
      open={isOpen}
      title={name}
      onClose={onClose}
      // style={{ width: 600 }}
    >
      {!sasToken && url ? (
        <Skeleton />
      ) : (
        <img
          id="image-modal-view"
          src={url ? `${url}?${sasToken}` : imageSrc}
          alt=""
          style={{ width: 700 }}
        />
      )}
    </AppModal>
  );
};
